import {createBrowserRouter} from "react-router-dom";
import React from "react";
import {
    Root,
    Error,
    Gallery,
} from './pages';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <Error />,
    },
    {
        path: "galerie",
        element: <Gallery />,
    },
]);

export default router
