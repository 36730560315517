import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBy4yXF4SA3F-yzsCzb-3wz4fUBss5j8cM",
    authDomain: "amandineetanthosemarient.firebaseapp.com",
    databaseURL: "https://amandineetanthosemarient-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "amandineetanthosemarient",
    storageBucket: "amandineetanthosemarient.appspot.com",
    messagingSenderId: "715487086394",
    appId: "1:715487086394:web:5ea8aace8e2d19ef3e7203"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
