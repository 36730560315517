import {useState, useEffect, useCallback} from 'react';
import {ref, uploadBytes, listAll, getDownloadURL} from 'firebase/storage';
import {v4} from 'uuid';
import {storage} from '../../firebase';
import './Gallery.css';

export default function Gallery()  {
    const [imageUpload, setImageUpload] = useState(null)
    const [imageList, setImageList] = useState([])
    const imageListRef = ref(storage, "images/")

    const fetchList = useCallback(async () => {
        const {items: images} = await listAll(imageListRef)
        for (const image of images) {
            const imageUrl = await getDownloadURL(image)
            setImageList((prev) => [...prev, imageUrl])
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchList()
    }, [fetchList])

    const uploadImage = async () => {
        try {
            if (!imageUpload) return
            const imageRef = ref(storage, `images/${imageUpload.name + v4()}`)
            const uploadImageRequest = await uploadBytes(imageRef, imageUpload)
            if (uploadImageRequest) fetchList()
        } catch (err) {
            throw new Error(err.message)
        }
    }

    return (
        <div className="Gallery">
            <input type="file" onChange={(e) => setImageUpload((e.target.files[0]))}/>
            <button onClick={uploadImage}>Upload</button>
            {imageList.map((imageUrl, index) => {
                return <img key={index} src={imageUrl} alt={index + 'img'}/>
            })}
        </div>
    );
}
